import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import createAppRouter from "./router";
import store from "./store";
import { isEmbedPWA } from "./services/utils";
import { setRegistration } from "./serviceWorker";
import VueSpatialNavigation from "vue-spatialnavigation";
import VueSignaturePad from "vue-signature-pad";
import linkify from "vue-linkify";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueTyper from "vue3-typer";
import amplitude from "@/plugins/amplitude";
import getAssets from "@/plugins/getAssets";
import translations from "@/plugins/translations";
import "vue3-typer/dist/vue-typer.css";
import "@/assets/styleguide.less"; // Global CSS
import "@/assets/tv-styleguide.less"; // Global CSS
import "@/assets/css/ui-kit.less"; // Global CSS
import "@/assets/icons.less"; // Global CSS
import "@/assets/loader.css"; // Global CSS

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://985ab954329c889a22f8ff1a26c9c980@o4506699496226816.ingest.us.sentry.io/4506902775332864",
  integrations: [Sentry.replayIntegration()],
  ignoreErrors: [
    /^Loading \w+ chunk|^Loading chunk/,
    /^Error: Request failed with status code 4[0-9]+/,
    /^Importing a module script failed\./,
    /^Unable to preload CSS for .*/,
    /^Load failed$/,
    /AxiosError: Request failed with status code/
  ],
  sampleRate: 0.01,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
  environment: import.meta.env.VITE_VUE_APP_ENVIRONMENT
});

app.use(createAppRouter);
app.use(store);
app.use(VueSpatialNavigation);
app.use(VueSignaturePad);
app.use(VueTyper);
app.use(getAssets);
app.use(translations, store);
app.use(
  amplitude,
  {
    apiKey: import.meta.env.VITE_VUE_APP_AMPLITUDE_API_KEY,
    configuration: {
      defaultTracking: {
        attribution: false,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false
      }
      // serverZone: "EU"
    }
  },
  store
);

app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB98FrS1n9r2b31H0dggl0vcK2jioBlSlE"
  }
});

app.config.productionTip = false;

app.directive("linkified", linkify);
app.config.globalProperties.$stripe = false;

const url_string = window.location.href;

let hashId;
if (url_string.indexOf("?id=") !== -1) {
  const components = url_string.split("?id=");
  hashId = components[1].substring(0, 4).replace("/", "");
}

if (!isEmbedPWA()) {
  setRegistration(hashId);
}

console.warn(
  `%c environment %c ${import.meta.env.VITE_VUE_APP_ENVIRONMENT} `,
  "background: #35495e; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;",
  "background: #8257CC; padding: 1px; border-radius: 0 3px 3px 0; color: #fff;"
);

app.mount("#app");

// Reload page on vite:preloadError (when there is a new build the chunk names
// change and if a user load another section without reloading the screen the CMS could freeze)
window.addEventListener("vite:preloadError", event => {
  console.error("vite:preloadError", event);
  window.location.reload();
});
